import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col } from "react-bootstrap"

const ImprintPage = () => {

    const data = useStaticQuery(graphql`
        query {
            strapiImprint {
                description
            }
        }
    `)

    return (


        <Layout>
            <SEO title="Kontakt" />

            <Container className={`py-0 py-md-5 my-5`}>
                <ReactMarkdown source={data.strapiImprint.description} escapeHtml={false} />
                
            </Container>

        </Layout>
    )
}

export default ImprintPage
